// @flow
import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, FormControl, FormHelperText, Grid, Typography} from "@mui/material";
import FileUpload from "../fileUpload/fileUpload";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import {useTranslation} from "react-i18next";
import List from "@mui/material/List";
import {ErrorMessage} from "formik";
import {AuthenticatedLink} from "../../general/authenticatedLink/authenticatedLink";
import {DownloadTwoTone} from "@mui/icons-material";
import {PAGE_HOME} from "../../../locales/pages/namespaces";

type Props = {};

const ImageWithPreview = (props: Props) => {
	const {
		accidentDetails,
		formik,
		fileField,
		pathField,
		getImageUrl
	} = props
	const {t} = useTranslation(PAGE_HOME);
	const [image, setImage] = useState('')
	const [openDialog, setOpenDialog] = useState(false);
	const handleCloseDialog = () => {
		setOpenDialog(false); // Close the dialog
	};
	return (
		<ListItem sx={{display: 'flex', flexWrap: 'wrap', width: '100%', maxWidth: '800px'}}>
			<Grid container sx={{width: {md: '100%', sm: '100%'}}}>
				<Grid item>
					{!formik.values[pathField] && (
						<FormControl
							variant="standard"
							fullWidth
							error={
								formik.touched[pathField] &&
								Boolean(formik.errors[pathField])
							}
						>
							<FileUpload
								allowedFiles={{'image/*': ['.jpeg', '.png', '.jpg']}}
								onFilesUpload={(value) => {
									const file = value[0];
									formik.setFieldValue(fileField, file);
									formik.setFieldValue(pathField, value[0].path)
									const reader = new FileReader();
									reader.onload = (event) => {
										setImage(event.target.result); // Set image URL for preview
									};
									reader.readAsDataURL(file);
								}}
								onRemove={(value) => {
									formik.setFieldValue(pathField, value[0].path)
								}}
							/>
							<FormHelperText>
								<ErrorMessage name={pathField}/>
							</FormHelperText>
						</FormControl>
					)}
				</Grid>
				<Grid item>
					<List sx={{width: '100%'}}>
						{formik.values[pathField] && (
							<ListItem sx={{
								display: 'flex',
								flexWrap: 'wrap',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
								{image ? (
									<Button onClick={() => setOpenDialog(true)}
											sx={{textWrap: 'wrap'}}>{formik.values[pathField]}</Button>
								) : (
									<AuthenticatedLink
										url={getImageUrl}
										filename={[pathField]}
									>
										<Typography variant={'body1'} sx={{display: 'flex', alignItems: 'center'}}>
											{formik.values[pathField]}
											<DownloadTwoTone/>
										</Typography>
									
									</AuthenticatedLink>
								)}
								<Button
									onClick={() => {
										formik.setFieldValue(pathField, '')
										formik.setFieldValue(fileField, '')
									}}>
									{t('Clear')}
								</Button>
							</ListItem>
						)}
					</List>
				</Grid>
			</Grid>
			<Dialog open={openDialog} onClose={handleCloseDialog}>
				<DialogContent>
					<img src={image} alt="Uploaded" style={{width: '100%'}}/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog}>{t('Close')}</Button>
				</DialogActions>
			</Dialog>
		</ListItem>
	);
};

export default ImageWithPreview