import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.PAGE_HOME]: {
		el: {
			'Warranty Number': 'Αριθμός Εγγύησης',
			'Name': 'Ονομα',
			'Activation Date': 'Ημερ.Ενεργοποίησης',
			'End Date': 'Ημερ.Λήξης',
			'Warranty registration shop': 'Κατάστημα',
			'Number of tires': 'Αριθμός ελαστικών',
			'Car brand': 'Μάρκα αυτοκινήτου',
			'Actions': 'Ενέργειες',
			'Warranty Details': 'Λεπτομέρειες Εγγύησης',
			'Approval Request': 'Αίτημα Έγκρισης',
			'Download Pdf': 'Κατέβασμα Pdf',
			'New warranty': 'Νέα Εγγύηση',
			'Warranties': 'Εγγυήσεις',
			'Sts tire code': 'Κωδικός ελαστικού STS',
			'Create New Warranty': 'Εισαγωγή νέας εγγύησης',
			'Product Details': 'Στοιχεία προϊόντος',
			'Publish Date': 'Ημ.Έκδοσης',
			'Expiration Date': 'Ημ.Λήξης',
			'Vehicle Details': 'Στοιχεία οχήματος',
			'Car Brand': 'Μάρκα',
			'Car Model': 'Μοντέλο',
			'License Pl.': 'Αρ.Κυκλοφορίας',
			'CC': 'Κυβ.Εκατοστά',
			'Km': 'Χιλιόμετρα',
			'Owner Details': 'Στοιχεία ιδιοκτήτη',
			'Surname': 'Επίθετο',
			'Address': 'Διεύθυνση',
			'Zipcode': 'Ταχ.Κώδικας',
			'City': 'Περιοχή',
			'Phone': 'Κινητό Τηλέφωνο',
			'Tire Selection': 'Επιλογή ελαστικών',
			'Shop Details': 'Στοιχεία Καταστήματος',
			'Tire Brand': 'Μάρκα',
			'Type': 'Τύπος',
			'D/T': 'Δ/Τ',
			'Same dimensions with set 1': 'Ίδιες διαστάσεις με το 1ο σετ',
			'|F| Car Brand': '|F| Μάρκα',
			'Width': 'Πλάτος',
			'Line': 'Σειρά',
			'Rim': 'Ζάντα',
			'Speed indicator': 'Δείκτης Ταχύτητας',
			'Car Sole': 'Πέλμα',
			'|B| Car Brand': '|B| Μάρκα',
			'Store': 'Κατάστημα',
			'Kryoneri Boulevard 20/Hellas': 'Λεωφόρος Κρυονερίου 20/Ελλάς',
			'PASXALIAS 60': 'ΠΑΣΧΑΛΙΑΣ 60',
			'NTAVIDI-EKIZANA KAI SIA IKE': 'ΝΤΑΒΙΔΗ-ΕΚΙΖΑΝΑ ΚΑΙ ΣΙΑ ΙΚΕ',
			'PASXALIAS 60/NTAVIDI-EKIZANA KAI SIA IKE': 'ΠΑΣΧΑΛΙΑΣ 60/ΝΤΑΒΙΔΗ-ΕΚΙΖΑΝΑ ΚΑΙ ΣΙΑ ΙΚΕ',
			'Please select tire': 'Παρακαλώ επιλέξτε ελαστικό',
			'The warranty number is not valid!': 'Ο αριθμός εγγύησης δεν είναι έγκυρος!',
			'Please enter  the correct  warranty number and try again!': ' Παρακαλώ πληκτρολογήστε  τον αριθμό εγγύησης και προσπαθήστε ξανά!',
			'Submit': 'Καταχώριση',
			'Warranty Image': 'Εικόνα Εγγύησης',
			'Receipt': 'Απόδειξη Αγοράς',
			'Consent for Newsletter': 'Εγγραφή στο Newsletter',
			'Consent with terms and conditions': 'Συμφωνώ με τους όρους χρήσης',
			'Accident Report': 'Δήλωση Ατυχήματος',
			'or': 'ή',
			'Scan QR code': 'Σκανάρετε τον κωδικό QR',
			'Required': '*Απαιτείται!',
			'Zip code': 'Ταχ.Κώδικας',
			'Store Name': 'Επωνυμία Καταστήματος',
			'Store Address': 'Διεύθυνση Καταστήματος',
			'If you want to replace your file just upload a new one!': 'Αν θέλετε να αντικαταστήσετε το αρχείο σας μπορείτε να ανεβάσετε ένα καινούριο!',
			'File name': 'Όνομα Αρχείου',
			'No camera detected on this device':'Δεν είναι δυνατή η χρήση κάμερας για αυτή την συσκευή',
			'Guarantee Pdf':'Αρχείο Ασφάλισης Ελαστικών',
			'Are you sure you want to send the information?':'Να σταλθούν τα στοιχεία;',
			'Send':'Αποστολή',
			'Cancel':'Ακύρωση',
			"There isn't any Guarantee with the given Number!": 'Δεν υπάρχει εγγύηση με τον συγκεκριμένο κωδικό που πληκτρολογήσατε!',
			'You have already activated this Guarantee successfully.': 'Έχετε ήδη ενεργοποιήσει την εγγύησή σας επιτυχώς.',
			"We are sorry, but the activation time limit has expired!": 'Λυπούμαστε, το επιτρεπόμενο διάστημα ενεργοποίησης της εγγύησής σας έχει λήξει!',
			"We are sorry, but the Guarantee has expired!": 'Λυπούμαστε, η εγγύησή σας έχει λήξει!',
			"The guarantee has already been used!": 'Η παρούσα εγγύηση έχει ήδη χρησιμοποιηθεί!',
			'Search for a new warranty code': 'Αναζήτηση νέου κωδικού εγγύησης',
			'Warranty Activation': 'Ενεργοποίηση Εγγύησης',
			'Clear': 'Καθαρισμός',
			'Enter a valid email': 'Εισάγετε ένα έγκυρο email',
			'Please fill all required fields and upload your receipt to continue!': 'Παρακαλώ συμπληρώστε όλα τα απαραίτητα πεδία και την απόδειξη αγοράς για να συνεχίσετε!',
			'Proceed to warranty submission': 'Επιβεβαίωση υποβολής στοιχείων',
			'If you wish to activate your warranty press SUBMIT.': 'Αν θέλετε να ενεργοποιήσετε την εγγύησή σας πατήσετε ΥΠΟΒΟΛΗ.',
			'Press CANCEL if you wish to go back and check your data.': 'Πατήσετε ΑΚΥΡΩΣΗ για να συνεχίσετε την επεξεργασία των στοιχείων σας.',
			'CANCEL': 'ΑΚΥΡΩΣΗ',
			'SUBMIT': 'ΥΠΟΒΟΛΗ',
			'Please download and save your PDF for future use of your warranty in case of accident.': 'Παρακαλώ κατεβάστε και αποθηκεύστε το PDF, για μελλοντική χρήστη της εγγύησης σε περίπτωση ατυχήματος.',
			'Download': 'Λήψη',
			'Download Warranty PDF': 'Λήψη PDF εγγύησης',
			'In case of losing warranty PDF you can re-enter your code in this app. PDF will be available for download.': 'Σε περίπτωση που χάσετε το PDF μπορείτε να ξανά εισάγετε τον κωδικό σας σε αυτήν την εφαρμογή. Το PDF θα είναι διαθέσιμο για λήψη.',
			'Warranty submission failed!': 'Η υποβολή της εγγύησης απέτυχε!',
			'Warranty activated successfully!': 'Η εγγύησή σας ενεργοποιήθηκε επιτυχώς!',
			'Enter a mobile number': 'Εισάγετε ένα κινητό τηλέφωνο',
			'Upload a picture of the receipt you received after buying your tire from the shop. (Up to 30Mb)': 'Εισάγετε φωτογραφία της απόδειξης που λάβατε κατά την αγορά του ελαστικού από το μαγαζί. (μέχρι 30Mb)',
			'Home':'Αρχική',
			'Not Found':'Η σελίδα δεν βρέθηκε',
			'Tire Guaranty Activation': 'Ενεργοποίηση ασφάλισης ελαστικών',
			'STEP 1': 'ΒΗΜΑ 1',
			'Type your guarantee code or scan your QR code': 'Γράψε τον αριθμό του εγγράφου ή σκάναρε τον κωδικό QR',
			'STEP 2': 'ΒΗΜΑ 2',
			'Type your personal info': 'Συμπλήρωσε τα προσωπικά σου στοιχεία',
			'STEP 3': 'ΒΗΜΑ 3',
			'Upload your tire receipt': 'Ανέβασε την απόδειξη αγοράς των ελαστικών',
			'COMPLETION': 'ΟΛΟΚΛΗΡΩΣΗ',
			'Check all your info and submit your guarantee': 'Έλεγξε όλα τα στοιχεία σου και ολοκλήρωσε την καταχώρησή σου',
			'READY!': 'ΕΤΟΙΜΟΙ!',
			'Now you can download your guarantee file': 'Πλέον μπορείς να κάνεις λήψη το αρχείο της ασφάλισής σου',
			'* To re-download your guarantee file, you can re-scan your QR or re-type your guarantee code!': '* Για να ξανά κάνετε λήψη του αρχείου ασφάλισης μετά την ενεργοποίησή της, μπορείτε να ξανά σκανάρετε τον κωδικό εγγράφου σας ή να ξανά εισάγετε τον κωδικό του.',
		},
		en: {
			'Warranty Number': 'Warranty Number',
			'Name': 'Name',
			'Activation Date': 'Activation Date',
			'End Date': 'End Date',
			'Warranty registration shop': 'Warranty registration shop',
			'Number of tires': 'Number of tires',
			'Car brand': 'Car brand',
			'Actions': 'Actions',
			'Warranty Details': 'Warranty Details',
			'Approval Request': 'Approval Request',
			'Download Pdf': 'Download Pdf',
			'New warranty': 'New warranty',
			'Warranties': 'Warranties',
			'Sts tire code': 'Sts tire code',
			'Phone': 'Mobile Phone',
		}
	}
}

export default locales