// @flow
import React from 'react';
import Images from "../../assets";
import Header from "./header/header";
import Box from "@mui/material/Box";
import ContentRouter from "../../routers/contentRouter";
import Footer from "./footer/footer";

type Props = {

};

const Layout1 = (props: Props) => {
	return (
		<Box sx={{
			display: 'flex',
			flexDirection:'column',
			justifyContent: 'space-between',
			height: '100%',
			minHeight: '400px',
			minWidth: '330px',
			backgroundImage: `url('${Images.background}')`,
			backgroundSize: `cover`,
			backgroundRepeat: 'no-repeat',
		}}>
			
			<Header/>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{props.children}
			</Box>
			<Footer/>
		</Box>
	);
};

export default Layout1