import * as React from 'react';
import Box from '@mui/material/Box';
import Logo from "../../app/logo/logo";
import LanguageSelect from "../../general/languageSelect/languageSelect";
import Instructions from "../../../pages/home/components/instructions";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useHistory} from "react-router-dom";

const Header = (props) => {
	const history = useHistory()
	return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					width: '100%',
					justifyContent: 'center',
					backgroundColor: '#000000',
					p: 0.4
				}}
			>
				<Logo
					style={{
						maxWidth: '80px',
						height: 'auto',
					}}
				/>
				<LanguageSelect/>
				<Box sx={{flexShrink: 0, ml: 0.75}}>
					<IconButton
						color="secondary"
						variant="light"
						sx={{color: 'text.primary', bgcolor:'grey.200'}}
						aria-label="open localization"
						aria-haspopup="true"
						onClick={()=>history.push('/instructions')}
					>
						<InfoOutlinedIcon/>
					</IconButton>
				</Box>
			</Box>
	);
}

export default Header;
