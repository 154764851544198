import {useFormik} from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../helpers/errorHandling";
import {PAGE_HOME} from "../locales/pages/namespaces";
import {API} from "../helpers/constants";
import {useState} from "react";


const useCustomerForm = ({list, code, data, setOpenPdf, handleClose}) => {
	const history = useHistory()
	const todayFormatted = dayjs().format('DD/MM/YYYY')
	const defaultEndDate = dayjs().add(12, 'months')
	const {t} = useTranslation(PAGE_HOME);
	const [isLoading, setIsLoading] = useState(false)
	
	const handlePdf = () => {
		setIsLoading(true)
		axios({
			method: 'get',
			
			url: `${API}/en/b2b/guarantee/pdf?GuaranteeNumber=${code}&final=1`,
			responseType: 'blob'
		}).then(response => {
			const blob = new Blob([response.data], {type: 'application/pdf'});
			const url = window.URL.createObjectURL(blob);
			
			// Create a link element
			const a = document.createElement('a');
			a.href = url;
			a.download = 'downloaded.pdf'; // Set the filename for download
			a.target = '_blank'; // Open in a new tab
			a.style.display = 'none';
			
			// Append the link element to the document and trigger the click event
			document.body.appendChild(a);
			a.click();
			
			// Open a new tab with the PDF
			window.open(url, '_blank');
			
			// Clean up by revoking the blob URL
			window.URL.revokeObjectURL(url);
			setIsLoading(false);
			history.push('/')
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Unable to download. PDF not found.!'), {
					variant: 'error',
				}
			))
			setIsLoading(false)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to download PDF!'), {
					variant: 'error',
				}
			))
			setIsLoading(false)
		})
	}
	
	const onSubmit = (values) => {
		setIsLoading(true)
		axios({
			method: 'post',
			headers: {'Content-Type': 'multipart/form-data'},
			data: {
				PlacedAddrId: values.PlacedAddrId.id,
				GuaranteeNumber: code,
				CustomerName: values.CustomerName,
				CustomerLastName: values.CustomerLastName,
				CustomerAddress: values.CustomerAddress,
				CustomerDistrict: values.CustomerDistrict,
				CustomerEmail: values.CustomerEmail,
				PostalCode: values.PostalCode,
				ReceiptImg: values.ReceiptImg,
				hasNewsletter: values.hasNewsletter,
				hasTerms: values.hasTerms,
				Name: values.Name.CustomerName,
				CustomerNumber: values.CustomerNumber
			},
			url: `${API}/el/b2b/guarantee/update`,
		}).then(response => {
			enqueueSnackbar(t('Warranty activated successfully!'), {
				variant: 'success',
				autoHideDuration: 2000,
			});
			setOpenPdf(true)
			setIsLoading(false)
			handleClose()
		}).catch((error) => {
			debugger
			enqueueSnackbar(handleAxiosErrors(error, t('Warranty submission failed!'), {
				variant: 'warning',
				autoHideDuration: 4000,
			}));
			setIsLoading(false)
		})
	}
	const validationSchema = yup.object({
		CustomerName: yup.string().required(t('Required')),
		CustomerLastName: yup.string().required(t('Required')),
		CustomerAddress: yup.string().required(t('Required')),
		CustomerDistrict: yup.string().required(t('Required')),
		CustomerNumber: yup.string()
			.matches(/^69[0-9]{0,8}$/, t('Enter a mobile number'))
			.required(t('Required')),
		PostalCode: yup.string().required(t('Required')),
		hasTerms: yup.bool().oneOf([true], t('Required')),
		ReceiptImgPath: yup.string().required(t('Required')),
		CustomerEmail: yup.string().email(t('Enter a valid email')).notRequired()
	})
	
	const formik = useFormik({
		initialValues: {
			PlacedAddrId: data?.id ? list.find((x) => x.id === data.id) : '',
			GuaranteeNumber: '',
			Name: data?.id ? list.find((x) => x.id === data.id) : '',
			CustomerName: '',
			CustomerLastName: '',
			CustomerAddress: '',
			CustomerDistrict: '',
			CustomerNumber: '',
			CustomerEmail: '',
			PostalCode: '',
			ReceiptImg: '',
			ReceiptImgPath: '',
			hasNewsletter: false,
			hasTerms: false
		},
		onSubmit: onSubmit,
		validationSchema: validationSchema
	})
	
	return {
		isLoading,
		formik,
		handlePdf
	}
}
export default useCustomerForm