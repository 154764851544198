import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../helpers/constants";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../locales/pages/namespaces";

const useCustomer = ({code}) => {
	const history = useHistory()
	const {t} = useTranslation(PAGE_HOME)
	const [list, setList] = useState([])
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	const [errorMessage, setErrorMessage] = useState(null)
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'post',
			data: {
				GuaranteeNumber: code
			},
			url: `${API}/el/b2b/guarantee/search`,
		})
		.then(response => {
			const isActive = response.data?.data?.isActive
			if (isActive === '0') {
				setList(response.data.list)
				setData(response.data.data)
			} else {
				history.push('/download/' + code)
			}
			setLoading(false)
		})
		.catch(error => {
			const message = error?.response?.data?.message
			setErrorMessage(message ? message : t('The warranty number is not valid!'))
			setLoading(false)
		})
	}, [])
	
	return {
		list,
		data,
		loading,
		errorMessage
	}
}
export default useCustomer