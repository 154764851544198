import {Paper, Typography} from "@mui/material";
import './homePage.css';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../../locales/pages/namespaces";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {enqueueSnackbar} from "notistack";
import {QrReader} from 'react-qr-reader';
import {LoadingButton} from "@mui/lab";
import {useHistory} from "react-router-dom";
import Layout1 from "../../../components/layout/layout1";

const HomePage = () => {
	const history = useHistory()
	const {t} = useTranslation(PAGE_HOME)
	const [qrData, setQrData] = useState('');
	const [cameraActive, setCameraActive] = useState(false)
	const [loadingScan, setLoadingScan] = useState(false)
	const qrReader = React.createRef()
	const [openQr, setOpenQr] = useState(false)
	const [code, setCode] = useState('')
	const [scannedCode, setScannedCode] = useState('')
	const [loading, setLoading] = useState(false)
	const [hasCameraPermission, setHasCameraPermission] = useState(true);
	
	useEffect(() => {
		const checkCameraPermission = async () => {
			try {
				const devices = await navigator.mediaDevices.enumerateDevices();
				const hasVideoDevice = devices.some((device) => device.kind === 'videoinput');
				setHasCameraPermission(hasVideoDevice);
				// if (!hasVideoDevice) {
				//     // Display a message when no camera is available
				//     enqueueSnackbar('No camera detected on this device', {
				//         variant: 'error',
				//     });
				// }
			} catch (error) {
				console.error('Error checking for camera permission:', error);
				setHasCameraPermission(false);
				// Handle the error as needed
			}
		};
		checkCameraPermission();
	}, []);
	
	const handleScanButtonClick = () => {
		if (!hasCameraPermission) {
			enqueueSnackbar(t('No camera detected on this device'), {
				variant: 'error',
			});
		} else {
			setCameraActive(true);
		}
	};
	
	const handleStopCamera = () => {
		setCameraActive(false)
	}
	
	return (
		<Layout1>
			<Box p={1} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
				<Paper
					elevation={0}
					sx={{maxWidth: '500px', width: '100%', p: 1, backgroundColor: 'rgba(255, 255, 255, 0.9)'}}
				>
					<Box>
						<Typography variant="h5" component="form">
							<Box sx={{
								width: '100%',
								display: 'flex',
								alignItems: 'flex-start',
								justifyContent: 'center',
								margin: '10px 0',
								gap: '30px'
							}}>
								<TextField
									sx={{width: '70%'}}
									label={t('Warranty Number')}
									variant={'standard'}
									value={code}
									onChange={(evt) => {
										setCode(evt.target.value)
									}}
								/>
								<LoadingButton
									variant={'contained'}
									sx={{width: 'fit-content', marginTop: '15px'}}
									loading={loading}
									loadingPosition="start"
									onClick={() => {
										history.push('/' + code)
									}}>
									{t('Submit')}
								</LoadingButton>
							</Box>
						</Typography>
						<Typography sx={{textAlign: 'center'}}>{t('or')}</Typography>
						<Box sx={{display: 'flex', justifyContent: 'center'}}>
							{cameraActive ? (
								<Button onClick={() => {
									handleStopCamera()
								}}>{t(' Stop Scanning QR code')}</Button>
							) : (
								<Button onClick={handleScanButtonClick}>{t('Scan QR code')}</Button>
							)}
						</Box>
						{cameraActive && hasCameraPermission && (
							<QrReader
								onResult={(result, error) => {
									if (!!result) {
										setQrData(result?.text);
										handleStopCamera()
										//qrReader.current.stop()
										//history.push('/form/' + code)
										window.location.href = result?.text
									}
									if (error) {
										console.info(error);
									}
								}}
								//onScan={handleScan}
								style={{width: '100%'}}
								videoContainerStyle={{
									width: '360px',
									height: '300px',
									marginTop: '6px',
									position: 'relative'
								}}
								constraints={{facingMode: 'environment'}}
								scanDelay={800}
							/>
						)}
					</Box>
				</Paper>
			</Box>
		</Layout1>
	)
}
export default HomePage