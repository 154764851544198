import LOGO from './logo/logo.png'
import background from './background/background.png'
import carPlan from './carPlan/carPlan.png'

const Images = {
	logo: LOGO,
	background: background,
	carPlan:carPlan
}

export default Images