import {useContext, useEffect, useRef, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import {
    Box,
    ClickAwayListener,
    Grid,
    IconButton,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@mui/material';
import {useTranslation} from "react-i18next";
import {TranslateOutlined} from "@mui/icons-material";
import Transitions from "../../@extended/transitions";
import {Languages} from "../../../helpers/constants";
import {LanguageContext} from "../../../App";

const LanguageSelect = () => {
    const {language, setLanguage} = useContext(LanguageContext);
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const {i18n} = useTranslation()
    useEffect(() => {
        i18n.changeLanguage(language)
    }, [i18n, language])
    
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    
    const handleListItemClick = (lang) => {
        setLanguage(lang);
        setOpen(false);
    };
    
    return (
        <Box sx={{flexShrink: 0, ml: 0.75}}>
            <IconButton
                color="secondary"
                variant="light"
                sx={{color: 'text.primary', bgcolor: open ? 'grey.300' : 'grey.200'}}
                aria-label="open localization"
                ref={anchorRef}
                aria-controls={open ? 'localization-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <TranslateOutlined/>
            </IconButton>
            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 0 : 0, 9]
                            }
                        }
                    ]
                }}
            >
                {({TransitionProps}) => (
                    <Transitions type="grow" position={matchesXs ? 'top-right' : 'top'} in={open} {...TransitionProps}>
                        <Paper sx={{boxShadow: theme.customShadows.z1}}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List
                                    component="nav"
                                    sx={{
                                        p: 0,
                                        width: '100%',
                                        minWidth: 200,
                                        maxWidth: 290,
                                        bgcolor: theme.palette.background.paper,
                                        borderRadius: 0.5,
                                        [theme.breakpoints.down('md')]: {
                                            maxWidth: 250
                                        }
                                    }}
                                >
                                    <ListItemButton selected={language === Languages.EN}
                                                    onClick={() => handleListItemClick(Languages.EN)}>
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                    <Typography color="textPrimary">English</Typography>
                                                    <Typography variant="caption" color="textSecondary"
                                                                sx={{ml: '8px'}}>
                                                        (EN)
                                                    </Typography>
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>
                                    <ListItemButton selected={language === Languages.EL}
                                                    onClick={() => handleListItemClick(Languages.EL)}>
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                    <Typography color="textPrimary">Ελληνικά</Typography>
                                                    <Typography variant="caption" color="textSecondary"
                                                                sx={{ml: '8px'}}>
                                                        (EL)
                                                    </Typography>
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default LanguageSelect;
