

import {useFormik} from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../helpers/errorHandling";
import {PAGE_HOME} from "../locales/pages/namespaces";
import {API} from "../helpers/constants";
import homePage from "../pages/home/components/homePage";
import {useState} from "react";


const useAccidentForm = ({list,code,data})=>{
    const history = useHistory()
    const todayFormatted = dayjs().format('DD/MM/YYYY')
    const defaultEndDate = dayjs().add(12,'months')
    const { t } = useTranslation(PAGE_HOME);



    const onSubmit = (values) =>{
        axios({
            method: 'post',
            data: {
                PlacedAddrId: values.PlacedAddrId.id,
                GuaranteeNumber:code
            },
            url:`${API}/el/b2b/guarantee/update`,
        }).then(response => {
            enqueueSnackbar( t('Success!'), {
                variant: 'success',
                autoHideDuration: 1000,
            });
            window.location.reload();
        }).catch((error) => {
            enqueueSnackbar(handleAxiosErrors(error, t('Failed!'), {
                variant: 'warning',
                autoHideDuration: 1000,
            }));
        })


    }


    const formik = useFormik({
        initialValues:{
            PlacedAddrId:data.id ? list.find((x)=>x.id === data.id):'',
        },
        onSubmit:onSubmit,
    })


    return{
        formik,

    }
}
export default useAccidentForm