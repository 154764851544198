import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";
import {Divider, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import {ArrowBack} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../../locales/pages/namespaces";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Layout2 from "../../../components/layout/layout2";

const Instructions = () => {
	const history = useHistory()
	const {t} = useTranslation(PAGE_HOME)
	return (
		<Layout2>
			<Box p={1} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
				<Paper
					elevation={0}
					sx={{maxWidth: '600px', width: '100%', backgroundColor: 'rgba(254, 244, 217, 0.9)'}}
				>
					<Box p={1}>
						<Box display={'flex'} justifyContent={'space-between'} mb={1}>
							<Button
								variant={'contained'}
								onClick={() => history.push('/')}
							>
								<ArrowBack fontSize={'small'}/>
								{t('Home')}
							</Button>
						</Box>
						<Box sx={{width: '100%', display: "flex", bgcolor: '#FFFFFF', flexDirection: 'column', p: 1}}>
							<Typography
								variant={'h2'}
								component={'div'}
								color={'primary'}
								sx={{textAlign: 'center'}}
							>
								{t('Tire Guaranty Activation')}
							</Typography>
							<List>
								<ListItem>
									<Typography variant={'h4'} component={'div'} color={'#FFFFFF'} sx={{width: '100%', backgroundColor: '#255b82', p: 0.5}}>
										{t('STEP 1')}
									</Typography>
								</ListItem>
								<ListItem>
									<Typography variant={'h5'} component={'div'} sx={{textAlign: 'center', width: '100%'}}>
										{t('Type your guarantee code or scan your QR code')}
									</Typography>
								</ListItem>
								<ListItem>
									<Typography variant={'h4'} component={'div'} color={'#FFFFFF'} sx={{width: '100%', backgroundColor: '#255b82', p: 0.5}}>
										{t('STEP 2')}
									</Typography>
								</ListItem>
								<ListItem>
									<Typography variant={'h5'} component={'div'} sx={{textAlign: 'center', width: '100%'}}>
										{t('Type your personal info')}
									</Typography>
								</ListItem>
								<ListItem>
									<Typography variant={'h4'} component={'div'} color={'#FFFFFF'} sx={{width: '100%', backgroundColor: '#255b82', p: 0.5}}>
										{t('STEP 3')}
									</Typography>
								</ListItem>
								<ListItem>
									<Typography variant={'h5'} component={'div'} sx={{textAlign: 'center', width: '100%'}}>
										{t('Upload your tire receipt')}
									</Typography>
								</ListItem>
								<ListItem>
									<Typography variant={'h4'} component={'div'} color={'#FFFFFF'} sx={{width: '100%', backgroundColor: '#255b82', p: 0.5}}>
										{t('COMPLETION')}
									</Typography>
								</ListItem>
								<ListItem>
									<Typography variant={'h5'} component={'div'} sx={{textAlign: 'center', width: '100%'}}>
										{t('Check all your info and submit your guarantee')}
									</Typography>
								</ListItem>
								<ListItem>
									<Typography variant={'h4'} component={'div'} color={'#FFFFFF'} sx={{width: '100%', backgroundColor: '#255b82', p: 0.5}}>
										{t('READY!')}
									</Typography>
								</ListItem>
								<ListItem>
									<Typography variant={'h5'} component={'div'} sx={{textAlign: 'center', width: '100%'}}>
										{t('Now you can download your guarantee file')}
									</Typography>
								</ListItem>
									<Divider/>
								<ListItem>
									<Typography variant={'h5'} component={'div'} color={'#f57c00'} sx={{textAlign: 'center'}}>
										{t('* To re-download your guarantee file, you can re-scan your QR or re-type your guarantee code!')}
									</Typography>
								</ListItem>
							</List>
						</Box>
					</Box>
				</Paper>
			</Box>
		</Layout2>
	)
}
export default Instructions