import {useMemo} from 'react';
import {CssBaseline, StyledEngineProvider} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';
import Palette from "./palette";

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({children}) {
	const theme = useMemo(() => Palette(), []);
	const themeTypography = useMemo(() => Typography(), []);
	const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);
	
	const themeOptions = useMemo(
		() => ({
			breakpoints: {
				values: {
					xs: 0,
					sm: 768,
					md: 1024,
					lg: 1266,
					xl: 1440
				}
			},
			direction: 'rtl',
			mixins: {
				toolbar: {
					minHeight: 60,
					paddingTop: 8,
					paddingBottom: 8
				}
			},
			palette: theme.palette,
			customShadows: themeCustomShadows,
			typography: themeTypography,
			spacing: 20,
			shape: {
				borderRadius: 4,
			},
		}),
		[theme.palette, themeCustomShadows, themeTypography]
	);
	
	const themes = createTheme(themeOptions);
	themes.components = componentsOverride(themes);
	
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themes}>
				<CssBaseline/>
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
}