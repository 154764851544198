import React from 'react';
import ContentRouter from "../../routers/contentRouter";
import './content.css'

const Content = () => {
	return (
		<ContentRouter/>
	
	)
}
export default Content