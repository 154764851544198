import {useHistory, useParams} from 'react-router-dom'
import useCustomer from "../../../hooks/useCustomer";
import {Fragment} from "react";
import Form from "./form";
import Box from "@mui/material/Box";
import {Alert, AlertTitle, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../../locales/pages/namespaces";
import Button from "@mui/material/Button";
import {ArrowBack} from "@mui/icons-material";
import Layout2 from "../../../components/layout/layout2";
import Layout1 from "../../../components/layout/layout1";
import ContentLoader from "../../../components/general/contentLoader/contentLoader";

const CustomerInfo = () => {
	const {id} = useParams()
	const history = useHistory()
	const {t} = useTranslation(PAGE_HOME)
	const {list, data, loading, errorMessage} = useCustomer({code: id})
	return !loading ? (
		<Fragment>
			{!errorMessage ? (
				<Layout2>
					<Form
						list={list}
						data={data}
						code={id}
					/>
				</Layout2>
			) : (
				<Layout1>
					<Box p={1} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
						<Paper
							elevation={0}
							sx={{maxWidth: '600px', width: '100%', backgroundColor: 'rgba(254, 244, 217, 0.9)'}}
						>
							<Box p={1}>
								<Box display={'flex'} justifyContent={'space-between'} mb={1}>
									<Button
										variant={'contained'}
										onClick={() => history.push('/')}
									>
										<ArrowBack fontSize={'small'}/>
										{t('Search for a new warranty code')}
									</Button>
								</Box>
								<Box>
									<Alert severity="warning">
										<AlertTitle>
											<strong>{t(errorMessage)}</strong>
										</AlertTitle>
									</Alert>
								</Box>
							</Box>
						</Paper>
					</Box>
				</Layout1>
			)}
		</Fragment>
	) : (
		<Layout1>
			<ContentLoader loading={loading} useCircularLoader/>
		</Layout1>
	)
}
export default CustomerInfo