import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../../locales/pages/namespaces";
import axios from "axios";
import {API} from "../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../../helpers/errorHandling";
import {useHistory, useParams} from "react-router-dom";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {ArrowBack, DownloadTwoTone} from "@mui/icons-material";
import Layout1 from "../../../components/layout/layout1";

const DownloadPdfForm = () => {
	const {id} = useParams()
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)
	const {t} = useTranslation(PAGE_HOME);
	const handlePdf = () => {
		setIsLoading(true)
		axios({
			method: 'get',
			url: `${API}/en/b2b/guarantee/pdf?GuaranteeNumber=${id}&final=1`,
			responseType: 'blob'
		}).then(response => {
			const blob = new Blob([response.data], {type: 'application/pdf'});
			const url = window.URL.createObjectURL(blob);
			// Create a link element
			const a = document.createElement('a');
			a.href = url;
			a.download = 'downloaded.pdf'; // Set the filename for download
			a.target = '_blank'; // Open in a new tab
			a.style.display = 'none';
			// Append the link element to the document and trigger the click event
			document.body.appendChild(a);
			a.click();
			// Open a new tab with the PDF
			window.open(url, '_blank');
			// Clean up by revoking the blob URL
			window.URL.revokeObjectURL(url);
			setIsLoading(false);
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Unable to download. PDF not found.!'), {
					variant: 'error',
				}
			))
			setIsLoading(false)
		}).catch(error => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to download PDF!'), {
					variant: 'error',
				}
			))
			setIsLoading(false)
		})
	}
	
	return (
		<Layout1>
			<Box p={1} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
				<Paper
					elevation={0}
					sx={{maxWidth: '400px', width: '100%', p: 1, backgroundColor: 'rgba(255, 255, 255, 0.9)'}}
				>
					<Box p={1}>
						<Box display={'flex'} justifyContent={'center'} mb={1}>
							<Button
								variant={'contained'}
								onClick={() => history.push('/')}
							>
								<ArrowBack fontSize={'small'}/>
								{t('Search for a new warranty code')}
							</Button>
						</Box>
						<Box sx={{width: "100%", textAlign: "center"}}>
							<Typography variant="subtitle1" sx={{mt: 2, mb: 1}}>
								{t("Guarantee Pdf")}
							</Typography>
							<Button onClick={handlePdf}>
								<DownloadTwoTone/>
								{t("Download Pdf")}
							</Button>
						</Box>
					</Box>
				</Paper>
			</Box>
		</Layout1>
	)
}
export default DownloadPdfForm