import './App.css';
import ThemeCustomization from "./theme";
import {CssBaseline} from "@mui/material";
import Main from "./pages/main/main";
import {Router} from "react-router";
import {createBrowserHistory} from "history";
import SnackbarProviderExtended from "./components/messages/snackbarProviderExtended/snackbarProviderExtended";
import React from "react";
import {Languages} from "./helpers/constants";

const history = createBrowserHistory();
export const LanguageContext = React.createContext(null)

function App() {
	const [language, setLanguage] = React.useState(Languages.EL)
	return (
		<LanguageContext.Provider
			value={{
				language: language,
				setLanguage: setLanguage
			}}
		>
			<ThemeCustomization>
				<SnackbarProviderExtended>
					<Router history={history}>
						<CssBaseline/>
						<Main/>
					</Router>
				</SnackbarProviderExtended>
			</ThemeCustomization>
		</LanguageContext.Provider>
	);
}

export default App;

