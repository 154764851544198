// @flow
import {closeSnackbar, MaterialDesignContent, SnackbarProvider} from "notistack";
import {IconButton, styled, useTheme} from "@mui/material";
import {grey} from "@mui/material/colors";
import {CancelOutlined} from "@mui/icons-material";

const SnackbarProviderExtended = (props) => {
	const theme = useTheme();
	const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
		'&.notistack-MuiContent-success': {
			backgroundColor: theme.palette.success
		},
		'&.notistack-MuiContent-warning': {
			backgroundColor: theme.palette.warning
		},
		'&.notistack-MuiContent-error': {
			backgroundColor: theme.palette.error
		},
		'&.notistack-MuiContent-info': {
			backgroundColor: theme.palette.info
		},
	}));
	return (
		<SnackbarProvider
			maxSnack={8}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			Components={{
				success: StyledMaterialDesignContent,
				error: StyledMaterialDesignContent,
				warning: StyledMaterialDesignContent,
				info: StyledMaterialDesignContent
			}}
			dense={true}
			preventDuplicate={true}
			action={(snackbarId) => (
				<IconButton
					onClick={() => closeSnackbar(snackbarId)}
					sx={{
						color: grey[900]
					}}
				>
					<CancelOutlined/>
				</IconButton>
			)}
		>
			{props.children}
		</SnackbarProvider>
	);
};

export default SnackbarProviderExtended