import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../../locales/pages/namespaces";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
	Autocomplete,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Paper
} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import useCustomerForm from "../../../hooks/useCustomerForm";
import {ErrorMessage, FormikProvider} from "formik";
import List from "@mui/material/List";
import {useHistory} from "react-router-dom";
import ImageWithPreview from "../../../components/form/imageWithPreview/imageWithPreview";
import {API} from "../../../helpers/constants";
import {Cancel, Check, DownloadRounded} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '1px solid #000',
	boxShadow: 24,
	p: 0,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center'
};
const Form = ({code, list, data}) => {
	const history = useHistory()
	const {t} = useTranslation(PAGE_HOME)
	const [open, setOpen] = useState(false)
	const [openPdf, setOpenPdf] = useState(false)
	
	const handleOpen = () => {
		formik.validateForm()
		.then((response) => {
			if (Object.keys(response).length === 0) setOpen(true)
			else enqueueSnackbar(t('Please fill all required fields and upload your receipt to continue!'), {
					variant: 'warning',
				}
			)
		})
	}
	
	const handleClose = () => {
		setOpen(false)
	}
	
	const handleClosePdf = (evt, reason) => {
		if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) return
		setOpenPdf(false)
	}
	
	const {formik, handlePdf, isLoading} = useCustomerForm({
		list,
		data,
		code,
		setOpenPdf,
		handleClose
	})
	
	return (
		<FormikProvider value={formik}>
			<Box
				p={{xs: 0.2, sm: 0.5, md: 1}}
				sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}
				component={'form'}
			>
				
				<Paper
					elevation={0}
					sx={{
						maxWidth: '500px',
						width: '100%',
						backgroundColor: 'rgba(255, 255, 255, 0.9)',
						paddingLeft: {
							xs: 1,
							sm: 2
						},
						paddingRight: {
							xs: 1,
							sm: 2
						},
						paddingTop: 0.5,
						paddingBottom: 0.5,
					}}
				>
					<Box>
						<Typography
							variant={'h4'}
							component={'div'}
							sx={{
								textAlign: 'center',
							}}
							pb={1}
						>
							{t('Warranty Activation')}
						</Typography>
						<Divider/>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								pt: 1
							}}
						>
							<Typography
								pb={1}
								variant={'subtitle1'}
								component={'div'}
								sx={{
									textAlign: 'center',
								}}
							>
								{t('Shop Details')}
							</Typography>
							<Box sx={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								mb: 0.4
							}}>
								<Autocomplete
									sx={{
										"& .MuiFormLabel-root.Mui-disabled": {
											WebkitTextFillColor: "#034b80",
										},
										"& .MuiInputBase-input.Mui-disabled": {
											WebkitTextFillColor: "#034b80",
										},
									}}
									options={list}
									isOptionEqualToValue={(opt, val) => {
										return opt.id === val.id || !val
									}}
									disabled
									getOptionLabel={option => option?.Address}
									value={formik.values.PlacedAddrId || null}
									onChange={(event, newValue) => {
										formik.setFieldValue('PlacedAddrId', newValue)
									}}
									onBlur={formik.handleBlur}
									size="medium"
									fullWidth
									renderInput={(params) => (
										<TextField
											label={t('Store Address')}
											size={'medium'}
											variant={'standard'}
											{...params}
											inputProps={{
												...params.inputProps,
												autoComplete: 'off',
											}}
										/>
									)}
								/>
							</Box>
							<Box sx={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								mb: 0.4
							}}>
								<Autocomplete
									sx={{
										"& .MuiFormLabel-root.Mui-disabled": {
											WebkitTextFillColor: "#034b80",
										},
										"& .MuiInputBase-input.Mui-disabled": {
											WebkitTextFillColor: "#034b80",
										},
									}}
									options={list}
									isOptionEqualToValue={(opt, val) => {
										return opt.id === val.id || !val
									}}
									disabled
									getOptionLabel={option => option?.CustName}
									value={formik.values.Name || null}
									onChange={(event, newValue) => {
										formik.setFieldValue('Name', newValue)
									}}
									onBlur={formik.handleBlur}
									size="medium"
									fullWidth
									renderInput={(params) => (
										<TextField
											label={t('Store Name')}
											size={'medium'}
											variant={'standard'}
											{...params}
											inputProps={{
												...params.inputProps,
												autoComplete: 'off',
											}}
										/>
									)}
								/>
							</Box>
							<Typography
								pt={1}
								variant={'subtitle1'}
								component={'div'}
								sx={{
									textAlign: 'center',
								}}
							>
								{t('Owner Details')}
							</Typography>
							<Box sx={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								mb: 0.4
							}}>
								<FormControl
									variant="standard"
									fullWidth
									error={
										formik.touched['CustomerName'] &&
										Boolean(formik.errors['CustomerName'])
									}
								>
									<TextField
										label={t('Name')}
										required
										value={formik.values.CustomerName}
										variant={'standard'}
										id="CustomerName"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
									/>
									<FormHelperText>
										<ErrorMessage name={'CustomerName'}/>
									</FormHelperText>
								</FormControl>
							</Box>
							<Box sx={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								mb: 0.4
							}}>
								<FormControl
									variant="standard"
									fullWidth
									error={
										formik.touched['CustomerLastName'] &&
										Boolean(formik.errors['CustomerLastName'])
									}
								>
									<TextField
										label={t('Surname')}
										required
										value={formik.values.CustomerLastName}
										variant={'standard'}
										id="CustomerLastName"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
									/>
									<FormHelperText>
										<ErrorMessage name={'CustomerLastName'}/>
									</FormHelperText>
								</FormControl>
							</Box>
							<Box sx={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								mb: 0.4
							}}>
								<FormControl
									variant="standard"
									fullWidth
									error={
										formik.touched['CustomerAddress'] &&
										Boolean(formik.errors['CustomerAddress'])
									}
								>
									<TextField
										label={t('Address')}
										value={formik.values.CustomerAddress}
										variant={'standard'}
										required
										id="CustomerAddress"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
									/>
									<FormHelperText>
										<ErrorMessage name={'CustomerAddress'}/>
									</FormHelperText>
								</FormControl>
							</Box>
							<Box sx={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								mb: 0.4
							}}>
								<FormControl
									variant="standard"
									fullWidth
									error={
										formik.touched['CustomerDistrict'] &&
										Boolean(formik.errors['CustomerDistrict'])
									}
								>
									<TextField
										label={t('City')}
										value={formik.values.CustomerDistrict}
										variant={'standard'}
										required
										id="CustomerDistrict"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
									/>
									<FormHelperText>
										<ErrorMessage name={'CustomerDistrict'}/>
									</FormHelperText>
								</FormControl>
							</Box>
							<Box sx={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								mb: 0.4
							}}>
								<FormControl
									variant="standard"
									fullWidth
									error={
										formik.touched['CustomerEmail'] &&
										Boolean(formik.errors['CustomerEmail'])
									}
								>
									<TextField
										label={t('Email')}
										value={formik.values.CustomerEmail}
										variant={'standard'}
										id="CustomerEmail"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
									/>
									<FormHelperText>
										<ErrorMessage name={'CustomerEmail'}/>
									</FormHelperText>
								</FormControl>
							</Box>
							<Box sx={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								mb: 0.4
							}}>
								<FormControl
									variant="standard"
									fullWidth
									error={
										formik.touched['CustomerNumber'] &&
										Boolean(formik.errors['CustomerNumber'])
									}
								>
									<TextField
										label={t('Phone')}
										value={formik.values.CustomerNumber}
										variant={'standard'}
										required
										id="CustomerNumber"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
									/>
									<FormHelperText>
										<ErrorMessage name={'CustomerNumber'}/>
									</FormHelperText>
								</FormControl>
							</Box>
							<Box sx={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								mb: 0.4
							}}>
								<FormControl
									variant="standard"
									fullWidth
									error={
										formik.touched['PostalCode'] &&
										Boolean(formik.errors['PostalCode'])
									}
								>
									<TextField
										label={t('Zip code')}
										value={formik.values.PostalCode}
										variant={'standard'}
										id="PostalCode"
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										required
									/>
									<FormHelperText>
										<ErrorMessage name={'PostalCode'}/>
									</FormHelperText>
								</FormControl>
							</Box>
							<Typography
								pt={1}
								variant={'subtitle1'}
								component={'div'}
								sx={{
									textAlign: 'center',
								}}
							>
								{t('Receipt')} *
							</Typography>
							<Box sx={{
								width: '100%',
								display: 'grid',
								alignItems: 'center',
								justifyContent: 'center',
								mt: 0.1
							}}>
								<FormControl
									variant="standard"
									sx={{width: '100%'}}
									error={
										formik.touched['ReceiptImg'] &&
										Boolean(formik.errors['ReceiptImg'])
									}
								>
									<FormHelperText>* {t('Upload a picture of the receipt you received after buying your tire from the shop. (Up to 30Mb)')}</FormHelperText>
									<List>
										<ImageWithPreview
											formik={formik}
											fileField={'ReceiptImg'}
											pathField={'ReceiptImgPath'}
											getImageUrl={`${API}/en/guarantee/${code}/image/Receipt`}
										/>
									</List>
								</FormControl>
							</Box>
							<Box sx={{
								width: '100%',
								display: 'flex'
							}}>
								<FormControl
									variant="standard"
									error={
										formik.touched['hasNewsletter'] &&
										Boolean(formik.errors['hasNewsletter'])
									}
								>
									<FormControlLabel
										control={
											<Checkbox
												checked={formik.values.hasNewsletter}
												onChange={(event) => formik.setFieldValue('hasNewsletter', event.target.checked)}
												size={'small'}
												name={'hasNewsletter'}
											/>
										}
										label={t('Consent for Newsletter')}
										labelPlacement="end"
									/>
									<FormHelperText>
										<ErrorMessage name={'hasNewsletter'}/>
									</FormHelperText>
								</FormControl>
							</Box>
							<Box sx={{
								width: '100%',
								display: 'flex'
							}}>
								<FormControl
									variant="standard"
									error={
										formik.touched['hasTerms'] &&
										Boolean(formik.errors['hasTerms'])
									}
								>
									<FormControlLabel
										control={
											<Checkbox
												checked={formik.values.hasTerms}
												onChange={(event) => formik.setFieldValue('hasTerms', event.target.checked)}
												size={'small'}
												name={'hasTerms'}
												required
											/>
										}
										label={t('Consent with terms and conditions')}
										labelPlacement="end"
									/>
									<FormHelperText>
										<ErrorMessage name={'hasTerms'}/>
									</FormHelperText>
								</FormControl>
							</Box>
							<Box
								sx={{
									display: 'flex',
									width: '100%',
									mt: 1
								}}
							>
								<Button
									variant={'contained'}
									color={'success'}
									onClick={handleOpen}
									fullWidth
								>
									{t('Submit')}
								</Button>
							</Box>
						</Box>
					</Box>
				</Paper>
				<Dialog
					open={open}
					onClose={handleClose}
					maxWidth={'xs'}
				>
					
					<DialogTitle>
						<Typography
							variant={'h4'}
							component={'div'}
						>
							{t('Proceed to warranty submission')}
						</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<Typography
								variant={'caption1'}
								component={'div'}
							>
								{t('If you wish to activate your warranty press SUBMIT.')}
								<br/>
								{t('Press CANCEL if you wish to go back and check your data.')}
							</Typography>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<LoadingButton
							color="error"
							size={"small"}
							variant={"contained"}
							onClick={handleClose}
							startIcon={<Cancel/>}
							sx={{
								marginRight: 'auto'
							}}
							loading={isLoading}
							loadingPosition="start"
						>
							{t('CANCEL')}
						</LoadingButton>
						<LoadingButton
							onClick={formik.submitForm}
							size={"small"}
							variant={"contained"}
							color={'success'}
							startIcon={<Check/>}
							loading={isLoading}
							loadingPosition="start"
						>
							{t('SUBMIT')}
						</LoadingButton>
					</DialogActions>
				</Dialog>
				<Dialog
					open={openPdf}
					onClose={handleClosePdf}
					maxWidth={'xs'}
					sx={{textAlign: 'center'}}
				>
					<DialogTitle>
						<Typography
							variant={'h4'}
							component={'div'}
						>
							{t('Download Warranty PDF')}
						</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<Typography
								variant={'caption1'}
								component={'div'}
								mb={1}
							>
								{t('Please download and save your PDF for future use of your warranty in case of accident.')}
								<br/>
								{t('In case of losing warranty PDF you can re-enter your code in this app. PDF will be available for download.')}
							</Typography>
						</DialogContentText>
						<Button
							onClick={handlePdf}
							size={"small"}
							variant={"contained"}
							color={'success'}
							startIcon={<DownloadRounded/>}
						>
							{t('Download')}
						</Button>
					</DialogContent>
				</Dialog>
			</Box>
		</FormikProvider>
	)
}
export default Form