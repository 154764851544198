// @flow
import React from 'react';
import Box from "@mui/material/Box";
import {Divider, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../../locales/pages/namespaces";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

const Footer = (props) => {
	const history = useHistory()
	const {t} = useTranslation(PAGE_HOME)
	return (
		<Box
			sx={{
				width: '100%',
				backgroundColor: 'primary.light',
				color: '#FFFFFF',
			}}
		>
			<Divider/>
			<Box sx={{display:'flex',justifyContent:'space-between'}}>
				<Typography variant="caption">&copy; {'All rights reserved'}</Typography>
				<Typography sx={{color: '#FFFFFF'}} variant="caption" component={Button} onClick={()=>history.push('/terms')} >{t('Terms & Conditions')}</Typography>
			</Box>

		</Box>
	);
};

export default Footer