// @flow
import React from 'react';
import Images from "../../../assets";
import {useHistory} from "react-router-dom";

const Logo = (props) => {
	const {
		auth = false,
		style = {}
	} = props
	const history = useHistory()
	return (
		<img
			onClick={() => {
				history.push('/')
			}}
			src={Images.logo}
			alt="logo"
			style={{
				cursor: "pointer",
				...style
			}}
		/>
	);
};

export default Logo