//@flow
import * as React from 'react'
import {downloadFile} from "../../../helpers/fileHandle";

type Props = {
	url: string,
	filename: string,
	children: React.Node,
}

export const AuthenticatedLink = (props: Props) => {
	const {url, filename, children, ...otherProps} = props
	// const headers = {'Authorization': `Bearer ${token}`}
	const handleAction = async () => {
		const result = await fetch(url, {
			// headers: headers
		})
		const blob = await result.blob()
		const href = window.URL.createObjectURL(blob)
		downloadFile(href, filename);
	}
	return (
		<a
			onClick={handleAction}
			{...otherProps}
		>
			{children}
		</a>
	)
}