export default function DataGrid(theme) {
	return {
		MuiDataGrid: {
			styleOverrides: {
				cell: {},
				columnHeaders: {},
				columnHeader: {},
				
			},
		},
	};
}
