import {Autocomplete, Container, Paper, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { PAGE_HOME } from "../../../locales/pages/namespaces";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { Modal } from "@mui/material";
import {Checkbox} from "@mui/material";
import Images from "../../../assets";
import useCustomerForm from "../../../hooks/useCustomerForm";
import {FormikProvider} from "formik";
import useAccidentForm from "../../../hooks/useAccidentForm";
import {API} from "../../../helpers/constants";
import axios from "axios";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../../helpers/errorHandling";
import {useHistory} from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Adjusted to be responsive
    maxWidth: 400, // Added maxWidth for smaller screens
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};

const AccidentForm = () => {
    const history = useHistory()
    const list = history.location.state.list
    const code = history.location.state.code
    const data = history.location.state.data
    const[isLoading,setIsLoading]= useState(false)
    const { t } = useTranslation(PAGE_HOME);
    const [open, setOpen] = useState(false);
    const [topBack,setTopBack] = useState(false)
    const [topFront,setTopFront] = useState(false)
    const [endBack,setEndBack] = useState(false)
    const [endFront,setEndFront] = useState(false)
    const warrantyNumber = code

    const handleCheckboxChange = (event) => {
        setTopBack(event.target.checked);
    };

    const handleTopChange =(event)=>{
        setTopFront(event.target.checked)
    }
    const handleEndBackChange = (event)=>{
        setEndBack(event.target.checked)
    }
    const handleEndFrontChange = (event)=>{
        setEndFront(event.target.checked)
    }

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const { formik } = useAccidentForm({
        list,
        data,
        code
    })
    const handlePdf = ()=>{
        setIsLoading(true)
        axios({
            method: 'get',

            url: `${API}/en/b2b/guarantee/pdf?GuaranteeNumber=${warrantyNumber}&final=1`,
            responseType: 'blob'
        }).then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);

            // Create a link element
            const a = document.createElement('a');
            a.href = url;
            a.download = 'downloaded.pdf'; // Set the filename for download
            a.target = '_blank'; // Open in a new tab
            a.style.display = 'none';

            // Append the link element to the document and trigger the click event
            document.body.appendChild(a);
            a.click();

            // Open a new tab with the PDF
            window.open(url, '_blank');

            // Clean up by revoking the blob URL
            window.URL.revokeObjectURL(url);

            enqueueSnackbar(t('Successfully downloaded PDF!'), {
                variant: 'success',
                autoHideDuration: 1000,
            });
            setIsLoading(false);
            formik.resetForm()
        }).catch(error => {
            enqueueSnackbar(handleAxiosErrors(error, t('Unable to download. PDF not found.!'), {
                    variant: 'error',
                }
            ))
            setIsLoading(false)
        }).catch(error =>{
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to download PDF!'), {
                    variant: 'error',
                }
            ))
            setIsLoading(false)
        })
    }

    return (
        <FormikProvider value={formik}>
            <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundImage: `url('${Images.background}')`,
                    backgroundPosition: `center`,
                    backgroundSize: `cover`,
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Paper elevation={0} sx={{
                    marginTop: {xs: '70px', md: '50px'},
                    minWidth: '70%',
                    paddingLeft: '15px',
                    paddingTop: '15px',
                    '@media (min-width: 280px) and (min-height: 653px)': {
                        marginLeft: '20px'
                    },
                }}>
        <Box sx={{ width: "100%", textAlign: "center" }}>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
                {t("Accident Report")}
            </Typography>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 2,
                }}
            >
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    {/*<Tooltip title={}></Tooltip>*/}
                    <Autocomplete
                        sx={{
                            "& .MuiFormLabel-root.Mui-disabled": {
                                WebkitTextFillColor: "#034b80",
                            },
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#034b80",
                            },
                        }}
                        options={list}
                        isOptionEqualToValue={(opt, val) => {
                            return opt.id === val.id || !val
                        }}
                        getOptionLabel={option => option?.Address.concat('/',option?.CustName)}
                        value={formik.values.PlacedAddrId || null}
                        onChange={(event, newValue) => {
                            formik.setFieldValue('PlacedAddrId', newValue)
                        }}
                        onBlur={formik.handleBlur}
                        size="medium"
                        disabled
                        fullWidth
                        renderInput={(params) => (
                            <TextField
                                label={t('Store')}
                                size={'medium'}
                                variant={'standard'}
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off',
                                }}
                            />
                        )}
                    />

                    <Button sx={{ width: "25%",mt:1 }} onClick={handleOpen}>
                        {t("Send")}
                    </Button>
                </Box>
            </Box>
            <Box sx={{display:'flex',justifyContent:'flex-start'}}>
                <Button onClick={handlePdf}>{t('Download Pdf')}</Button>
            </Box>
            <Typography sx={{mt:2,display:'flex',justifyContent:'flex-start'}}>{t('Please select tire')}</Typography>
            <Box sx={{ mt: 2, width: "100%", maxWidth: 200, mx: "auto" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Checkbox
                        checked={topBack}                  //formik.values.topBack
                        onChange={handleCheckboxChange}                       //(evt) => {formik.setFieldValue(`topBack`, evt.target.checked) }
                        name={`topBack`}
                    />
                    <Checkbox
                        checked={topFront}                  //formik.values.topBack
                        onChange={handleTopChange}                       //(evt) => {formik.setFieldValue(`topBack`, evt.target.checked) }
                        name={`topFront`}
                    />
                </Box>
                <Box>
                    <img src={Images.carPlan} style={{ width: "100%", maxWidth: 200 }} />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Checkbox
                        checked={endBack}                  //formik.values.topBack
                        onChange={handleEndBackChange}                       //(evt) => {formik.setFieldValue(`topBack`, evt.target.checked) }
                        name={`endBack`}
                    />
                    <Checkbox
                        checked={endFront}                  //formik.values.topBack
                        onChange={handleEndFrontChange}                       //(evt) => {formik.setFieldValue(`topBack`, evt.target.checked) }
                        name={`endFront`}
                    />
                </Box>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>
                        <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }}>
                            {t("Are you sure you want to send the accident report?")}
                        </Typography>
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <Button sx={{ textAlign: "center" }} onClick={handleClose}>
                            {t("Cancel")}
                        </Button>
                        <Button sx={{ textAlign: "center" }} onClick={formik.submitForm}>{t("Send")}</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
                </Paper>
            </Container>
        </FormikProvider>
    );
};

export default AccidentForm;
