import Layout1 from "../../components/layout/layout1";
import Box from "@mui/material/Box";
import {Paper} from "@mui/material";
import Button from "@mui/material/Button";
import {ArrowBack} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {PAGE_HOME} from "../../locales/pages/namespaces";
import {useHistory} from "react-router-dom";

const NotFound = () => {
    const history = useHistory()
    const {t} = useTranslation(PAGE_HOME)
    return (
       <Layout1>
           <Box p={1} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
               <Paper
                   elevation={0}
                   sx={{maxWidth: '600px', width: '100%', backgroundColor: 'rgba(254, 244, 217, 0.9)'}}
               >
                   <Box p={1}>
                       <Box display={'flex'} justifyContent={'space-between'} mb={1}>
                           <Button
                               variant={'contained'}
                               onClick={() => history.push('/')}
                           >
                               <ArrowBack fontSize={'small'}/>
                               {t('Home')}
                           </Button>
                       </Box>
                       <Box sx={{width:'100%',display:"flex",bgcolor:'#FFFFFF',justifyContent:'center'}}>
                           <Typography sx={{p:1}}>{t('Not Found')}</Typography>
                       </Box>
                   </Box>
               </Paper>
           </Box>
       </Layout1>
    )
}
export default NotFound;
