import React from 'react'
import {Route, Switch} from 'react-router'
import NotFound from "../pages/notFound/notFound";
import AccidentForm from "../pages/home/components/accidentForm";
import HomePage from "../pages/home/components/homePage";
import CustomerInfo from "../pages/home/components/customerInfo";
import DownloadPdfForm from "../pages/home/components/downloadPdfForm";
import Terms from "../pages/terms/terms";
import Instructions from "../pages/home/components/instructions";

const ContentRouter = () => {
	return (
		<Switch>
			<Route
				key={'home'}
				path={'/'}
				exact
				component={HomePage}
			/>
			
			<Route
				key={'page.download'}
				path={'/download/:id'}
				exact
				component={DownloadPdfForm}
			/>
			<Route
				key={'page.accident'}
				path={'/accident/:id'}
				exact
				component={AccidentForm}
			/>
			<Route
				key={'page.terms'}
				path={'/terms'}
				exact
				component={Terms}
			/>
			<Route
				key={'page.instructions'}
				path={'/instructions'}
				exact
				component={Instructions}
			/>
			<Route
				key={'page.form'}
				path={'/:id'}
				exact
				component={CustomerInfo}
			/>
			<Route path={'/'}>
				<NotFound/>
			</Route>
		</Switch>
	
	)
}

export default ContentRouter